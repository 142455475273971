.titleLineBody{
    background-color: #fff;
    height: 65px;
    
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    
    
}
.checkBox{
    align-items: center;   
}
.titleBox{
    
    display: flex;
    align-items: center;
    color:  #000;
    padding-left: 2vw;
}
.titleBoxData{
    
    display: flex;
    align-items: center;
    color:black;
    font-size: 13px;
    padding-right: 10px;
}
.titleIcon{
    width: 12px;
    padding-left: 3px;
}
.titleIconEdit{
    width: 20px;
    margin-left: 15px;
}

.proposal-detail, .proposal-detail:hover {
  max-height: 11rem;
    background-color: #0F5796;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    padding-top: 20px;
    padding-bottom: 40px;
  
}

.proposal-detail-header {
    display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.proposal-detail-section {

    display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.horizontal-rule {
    border: 1px solid #F0F0F0;
    margin: '20px 0'
   
  }

.proposal-button-content, .proposal-button-content:hover {
    display: flex;
  align-items: center;
  justify-content: left;
  background-color: #fff;
  padding: 5px;
  padding-left: 8px;
  border-radius: 10px;
  text-decoration: none;
  width: 145px;
}

.proposal-button-icon-background{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff3ed;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  border-radius: 6px;
}

.proposal-button-icon {
    width: 20px;
  height: 20px;
  
}

.proposal-button-info {
    display: flex;
    flex-direction: column;
}

.proposal-button-info p {
    font-size: 9px;
  color: #686868;
  margin: 3px 0;
}

.proposal-button-info h3 {
    font-size: 12px;
    font-weight: 500;
  color: #000000;
  margin: 3px 0;
}

.proposal-buttons-container {
    display: flex;
    gap: 20px;
}

.proposal-buttons-container-inside{
  display: flex;
  align-items: center;
  justify-content: left;
  
}

.proposal-buttons-container-inside > img{
  margin-right: 10px;
}



/* TwoColumnLayout.css */

.two-column-layout {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 5px;
  }
  
  .proposal-left-column, .proposal-left-column:hover {
    flex: 1;
    
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
  }
  
  .proposal-right-column, .proposal-right-column:hover {
    flex: 1;
    max-width: 25%;
    margin-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  .save-button,
  .view-button, .save-button:hover, .view-button:hover {
    padding: 10px 20px;
    background-color: #E5EDF3;
    color: #0F5796;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .note {
    margin-bottom: 20px;
  }
  


  /* DetailsSection.css */

.details-section {
    display: flex;
    padding: 20px;
    border: none;
    border-radius: 5px;
  }
  
  
  .detail-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .detail-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .detail-value {
    color: #666;
  }
  
  .note-section {
    margin-top: 20px;
  }
  
  .add-note {
    display: flex;
    align-items: center;
  }
  
  textarea {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .save-button {
    padding: 8px 16px;
    background-color: #E5EDF3;
    color: #0F5796;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  