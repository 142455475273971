.login-screen, .login-screen:hover {
    display: flex;
  flex-direction: column;
  align-items: center;
    background-color: #F5FAFF;
  height: 100vh;
  }
  
  .header-login {
    display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  }

  .header-login h1 {
      padding-left: 10%;
  }
  
  .logo {
    max-width: 150px; /* Adjust the logo size as needed */
  }
  
  .create-account-text {
    font-size: 26px;
    padding-right: 20px;
  }

  .need-help-text {
    font-size: 20px;
    padding-right: 20px;
    align-self:right;
  }

  .create-text  a{
      color: #0F5796;
      text-decoration: none;
  }
  
  .form-container {
    margin-top: 20px;
  }
  
  .login-form {
    max-width: 500px;
    padding: 20px;

  }

  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    color: #0F5796;
  }

  .login-form p {
    text-align: center;
    margin-left: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    width: 80%;

  }
  
  .form-group {
    margin-bottom: 15px;
    position: relative;
  }

  .form-duo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  .form-duo input {
    width: 40%;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 96%;
    /* height: 40px; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 28px;
    height: 28px;
  }

  .eye-icon-grey{
    filter: grayscale(1);
  }
  
  .login-button, .login-button:hover {
    display: block;
    width: 100%;
    height: 80px;
    padding: 10px;
    background-color: #0F5796;
    color: #fff;
    font-size: 26px;
    margin-top: 10%;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .forgot-password-link a {
      text-decoration: none;
      color: #0F5796;
      font-size: 16px;
  }

  {/** Start Sign up choose **/}


  .cards-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .card {
    flex: 1;
    padding: 20px;
    padding-top: 10%;
    padding-left: 80px;
    padding-right: 80px;
    max-width: 350px;
    max-height: 350px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    margin: 0px;
  }

  .choose-title {
    font-size: 40px;
    color: #0F5796;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .card h3 {
    font-size: 40px;
    color: #0F5796;
    margin-top: 10px;
    text-decoration: none;
  }
  
  .card img {
    width: 209px;
    height: 209px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .card a{
    color: #0F5796;
    text-decoration: none;
}
  
  .or-button-container {
    flex: 0;
    position: absolute;
    left: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .or-button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #0F5796;
    font-size: 30px;
    
  }

  .sign-checkbox-container {
    margin-top: 10px;
    justify-content: flex-start;
  }


  
  .sign-checkbox-container label {
    margin-right: 10px;
    color: gray;
  }

  .sign-checkbox-container a {
    color: gray;
  }




    {/** End Sign up choose **/}

    {/** Start Oboarding **/}

    .onboarding-screen, .onboarding-screen:hover {
      background-color: #F5FAFF;
      height: 100vh;
      
    }

    .onboarding-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      
      padding: 20px;
    }
    
    .left-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 20%;
      
      
    }
    
    .header-onboarding {
      text-align: center;
      display: flex;
      flex-direction: row;
      
    }

    .left-column-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .header-onboarding button {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0px 20px;
      border: 1px solid #0F5796;
      border-radius: 5px;
      background-color: #fff;
      color: #0F5796;
      cursor: pointer;
      
    }

    .header-onboarding button img {
      max-width: 24px;
      height: 24px;
      margin: 20px 0;
    }
    
    .header-onboarding img {
      max-width: 108px;
      height: 108px;
      margin-right: 20px;
    }

    .header-onboarding h2 {
      color: #0F5796;
      font-size: 26px;
      margin-right: 40px;
      margin-bottom: 10px;
      
    }

    .bottom-buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 40px;
    }

    .btn-finish, .btn-finish:hover {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      border: none;
      
      border-radius: 5px;
      background-color: #0F5796;
      color: #fff;
      cursor: pointer;
    }

    .btn-skip {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      border: 1px solid #0F5796;
      border-radius: 5px;
      background-color: #fff;
      color: #0F5796;
      cursor: pointer;
    }

    .bottom-buttons button img {
      width: 14px;
      height: 14px;
    }
    
    .buttons {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }
    
    .survey-inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 15%;
      margin-top: 5%;
      margin-right: 15%;
      gap: 10px;
    }
    
    .survey-inputs input {
      width: 100%;
      height: 45px;
      background-color: #fff;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .survey-inputs label {
      color: #000;
    }
    
    .navigation-buttons {
      display: flex;
      gap: 10px;
    }

    .navigation-buttons img {
      width: 24px;
      height: 24px;
    }
    
    .right-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: 30%;
      
      
    }
    
    .right-column-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .checkout {
      background-color: #fff;
    }
    
    .right-column-buttons button {
      display: flex;
      align-items: center;
      width: 60%;
      gap: 10px;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      background-color: #0F5796;
      color: #fff;
      cursor: pointer;
    }

    .right-column-buttons button img {
      width: 21px;
      height: 21px;
    }
    
    {/** End Oboarding **/}
  