.create-task {
    .create-task-form {
        label {
            font-size: 18px;
        }

        input, .quill-editor{
            margin-top: 15px;
        }

        input {           
            padding: 20px 20px;
            box-sizing: border-box;
        }

        input::placeholder {
            color: #6A707E;
        }

        .create-task-input{
            margin-bottom: 20px;
        }

        .campaign-name:disabled{
            background-color: white;
        }

        .error{
            font-size: 12px;
            color: red;
            margin-top: 2px;
            margin-left: 2px;
        }

        .date-column {
            display: flex;
            justify-content: space-between;
            column-gap: 100px;
            margin-bottom: 20px;

            .date-column-item{
                flex:1;
            }

            label {
                display: block;
            }
        }

        .quill-editor {
            height: 100% !important;
            width: 100%;
            padding: 0px;
            border-radius: 30px;
            background-color: white;

            .ql-container{
                height: 100px;
            }
        }

    }

}