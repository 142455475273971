/* Dashboard Page Styles */
.dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #F5FAFF;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 250px;
  }
  
  .content {
    flex: 1;
    display: flex;
    padding: 20px;
    padding-left: 80px;
  }

  .create-content {
    flex: 1;
    display: flex;
    padding: 20px;
    padding-left: 80px;
  }

  .horizontal-rule {
    border: 1px solid #F0F0F0;
    margin: '20px 0'
   
  }

  .left-proposal {
    max-width: 40%;
    padding: 40px;
    margin-left: 50px;
    background-color: #fff;
  }

  .create-left-proposal {
    flex: 1;
    padding: 40px;
    
    
  }
  
  .add-business-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tabs {
    margin-top: 20px;
  }
  
  .tab {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .right-columns {
    flex: 1;
    padding: 20px;
    width: 500px;
  }

  .create-right-column {
    flex: 1;
    padding: 20px;
    max-width: 30%;
   
  }
  
  .business-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .business-table th,
  .business-table td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tabs button {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #0F5796;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
  }

  .tabs img {
    width: 21px;
    height: 21px;
  }

  /* BusinessManagementHeader.css */

.business-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  
}

.header-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  
}

.subtitle {
  font-size: 14px;
  color: #666;
}

.all-button {
  display: flex;
    align-items: center;
    width: 125px;
    height: 45px;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #F0F0F0;
    color: #0F5796;
    cursor: pointer;
    margin-top: 20px;
}

.all-button img {
  width: 21px;
  height: 21px;
}

/* Card.css */

.proposal-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0px;
  height: 160px;
  background-color: #fff;
}

.proposal-image-container {
  background-color: #C4C4C4;
  width: 54px;
  height: 44px;
  border-radius: 30%;
  margin-right: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.thumbnail {
  background-color: #ababab;
  width: 100%;
  height: 100%;
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px; 
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: uppercase; 
  color: white;
}

.card-image {
  width: 24px;
  height: 24px;

  margin-right: 20px;
}

.proposal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-container {
  flex-grow: 1;
}

.proposal-card-title {
  font-size: 18px;
  color: black;
  margin: 0;
}

.card-description {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0;
}

.expand-icon {
  font-size: 1.5rem;
  color: #999;
}

.proposal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.vertical-rule {
  width: 1px;
  height: 40px; /* Adjust the height as needed */
  background-color: #ccc;
  align-self: center;
}

.industry-button,
.template-button {
  padding: 8px 12px;
  margin-right: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.industry-button {
  display: flex;
  align-items: center;
  background-color: #ffeaca;
  color: #FFBB54;
}

.template-button {
  background-color: #d1e7fa;
  color: #0F5796;
}

/* QuillEditor.css */

.quill-editor {
  height: 100% !important;
  width: 100%;
  padding: 0px;
  margin-top: 20px;
  border-radius: 30px;
}

/* SearchDropdown.css */

.search-dropdown {
  width: 500px;
  margin-right: 20px;
  text-align: center;
}

.input-container {
  position: relative;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.plus-button {
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #fff;
  color: #0F5796;
  border: 1px solid #0F5796;
  border-radius: 20;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  width: 100px;
  z-index: 1;
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #f0f0f0;
}

.right-columnsl .proposal-content {
  display: block;
}


.detail-subtext{
  font-size: 14px;
  color: #A3A3A3;
}
.detail-subtext *{
  font-size: inherit;
  color: inherit;
  font-weight: normal;
}