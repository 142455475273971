/* Dashboard Page Styles */
.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #F5FAFF;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0px;
}

.content {
  flex: 1;
  display: flex;
  padding: 20px;
  padding-left: 80px;
}

.horizontal-rule {
  border: 1px solid #F0F0F0;
  margin: '20px 0'

}

.left-side {
  height: 375px;
  padding: 20px;
  width: 250px;
  background-color: #fff;
}

.right-columns {
  flex: 1;
  padding: 20px;
  width: 500px;
}

.tabs img {
  width: 21px;
  height: 21px;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;

}

.subtitle {
  font-size: 14px;
  color: #666;
}

.onboarding-client-form {
  label {
    font-size: 18px;
  }

  input {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 20px 20px;
  }

}