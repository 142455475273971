.cover-div{
    display: flex;
    margin-bottom: 2rem;
    width: 100%;
}


.agenda-section-one{
    /* width: 12vw; */
    font-size: 20px;
    min-width: 14vw;
}

.agenda-section-two{
    width: 100%;
    /* max-width: 20vw; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

}

.template-button{
    color: #0F5796;
    font-weight: 500;
    background-color: #E7EFF5;
    margin-right: 0;
    margin-top: 0;
}

.create-agenda-button>button{
    padding: 10px 20px;
    margin-top: 0.5rem;
}

.notes-section-three{
    width: 100%;
    max-width: 20rem;

}

.white-bg{
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    min-width: 25vw;
}

.flex{
    display: flex;
    justify-content: space-between;
}

.mb{
    margin-bottom: 1rem;
}

.mt{
    margin-top: 1rem;
}

::placeholder { 
    color: #0F5796; 
}



.card-image{
    height: 30px;
}

  
.odd-border {
    border-bottom: 1px solid #e0e0e0; /* Add bottom border for odd cards */
  }

  .proposal-card{
    padding: 1.5rem 1.5rem;
  }

  .pagination{
    margin-top: 0;
  }

  .css-13cymwt-control{
    padding-left: 10px;
    height: 10px;
  }


.quill-editor-notes-1 > div {
    margin-left:5vw;
}
  

.quill-editor-notes-1 .ql-container {

    height: 25vh;
}

.quill-editor-1 {
    height: 100% !important;
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    border-radius: 30px;
  }

  .quill-editor-1 .ql-toolbar {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

.quill-editor-1 .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .primaryNotes{
    color: #3d3d3d !important;
  }