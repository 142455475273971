.pagination-container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
  }
  

  /* Pagination.css */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.page-number {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 5px;
  color: #0F5796;
  font-weight: bold;
  background: none;
  cursor: pointer;
}

.current-page {
  color: #4F7086;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
  background-color: #fff;
}
.centered-pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centered-pagination .page-number{
  background-color: inherit;
  color: rgba(30, 136, 229, 0.5);
}

.centered-pagination .current-page{
  color: #0F5796;
  background-color: inherit;
  box-shadow: none;
}

.centered-pagination img[alt="next"]{
  align-self: flex-end;
  margin-left: auto;
}
 
.centered-pagination img[alt="previous"]{
  align-self: flex-start;
  margin-right: auto;
}