.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: rgb(245, 250, 255);
}

.hide-input-number-scroll::-webkit-outer-spin-button,
.hide-input-number-scroll::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-input-number-scroll {
  -moz-appearance: textfield;
}

.onboarding-completed-form .popup .quill-editor .ql-container{
  height: 180px;
}

.onboarding-completed-form .quill-editor .ql-container p strong{
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 18px;
}

.onboarding-completed-form .quill-editor .ql-container p span{
  display: inline-block;
  margin-left: 28px;
  font-size: 16px;
}

.export-report .quill-editor .ql-container{
  height: 350px;
}

.export-report .quill-editor .ql-container p{
  font-size: 14px;
}

.edit-report-container input:disabled{
  -webkit-text-fill-color: black;
  background-color: white;
  border-radius: 10px;
}
