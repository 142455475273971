::placeholder { 
    color: #565656; 
}

.quill-editor-notes-2 > div {
    background: #FFF;
    
  }

  .quill-editor-notes-2 .ql-container {
    height: 14vh;
    width: 100%;
  } 


  .quill-editor-2 .ql-toolbar {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

.quill-editor-2 .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }




  /* .quill-editor-notes > div {
    background: #FFF;
  }
  
  .quill-editor-notes .ql-container {
    height: 14vh;
  } */