/* Header */
.toastui-calendar-layout {
    background-color: rgb(245, 250, 255) !important;
    height: 200% !important;
}

.toastui-calendar-day-view .toastui-calendar-panel:not(.toastui-calendar-time),
.toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time) {
    overflow: visible !important;
    height: 80px !important;
    background-color: white !important;
    border: 0px !important;
    border-radius: 10px !important;
}

.toastui-calendar-day-names.toastui-calendar-week {
    border: 0px !important;
}

.toastui-calendar-day-name-container,
.weekly-report .toastui-calendar-day-names {
    margin: 0 18px !important;
}

/* button */
.cutsom-left-report-btn {
    position: absolute;
    left: 20px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
    border: none;
    background-color: #0F5796;
    border-radius: 10px;
    padding: 5px 10px;
}

.cutsom-right-report-btn {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
    border: none;
    background-color: #0F5796;
    border-radius: 10px;
    padding: 5px 10px;
}

.toastui-calendar-template-time{
    height: 100% !important;
}

/* main Section */
.toastui-calendar-panel.toastui-calendar-time {
    margin-top: 25px;
    background-color: white !important;
    overflow: visible !important;
}

.toastui-calendar-timegrid {
    background-color: white !important;
    margin-left: -34px !important;
}

.toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-hour-rows {
    background-color: rgb(245, 250, 255) !important;
    border-left: none !important;
}

.toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time .toastui-calendar-timegrid-time-label,
.toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time span {
    color: black !important;
    font-size: 13px !important;
}

.toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-today {
    border-top: 1px solid #0F5796 !important;
}

.toastui-calendar-timegrid .toastui-calendar-timegrid-now-indicator .toastui-calendar-timegrid-now-indicator-marker {
    background-color: #0F5796 !important;
}

.toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-current-time {
    background-color: #0F5796 !important;
    color: white !important;
    padding: 3px 5px;
    color: white;
    border-radius: 15px;
}

.toastui-calendar-event-time {
    border-left: 4px solid #0F5796 !important;
    background-color: rgba(15, 87, 150, 0.15) !important;
    ;
}

.toastui-calendar-timegrid .toastui-calendar-columns .toastui-calendar-column {
    background-color: transparent !important;
    border-right: none !important;
}

.toastui-calendar-column .toastui-calendar-events {
    background-color: white !important;
}

/* Arror on pop up */
.arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.arrow-up {
    bottom: 100%;
    /* Position it at the bottom of the popup */
    left: 95%;
    /* Center the arrow */
    margin-left: -10px;
    /* Adjust the position to truly center it */
    border-bottom: 10px solid white;
    /* Color of the arrow */
}

.arrow-down {
    top: 100%;
    /* Position it at the top of the popup */
    left: 95%;
    /* Center the arrow */
    margin-left: -10px;
    /* Adjust the position to truly center it */
    border-top: 10px solid white;
    /* Color of the arrow */
}

/* You can create .arrow-left and .arrow-right similarly */

/* Month */

.toastui-calendar-month .toastui-calendar-day-names {
    height: 51px !important;
    padding: 10px !important;
}

span .toastui-calendar-template-monthDayName {
    color: black !important;
}

.toastui-calendar-month .toastui-calendar-month-daygrid {
    margin-top: 20px;
}

.monthly-report .toastui-calendar-grid-cell-date {
    display: inline-block !important;
    padding: 10px 15px !important;
}

.monthly-report .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
    color: #0F5796 !important;
    background-color: white !important;
    font-weight: bold !important;
    font-size: 24px !important;
}

.toastui-calendar-weekday-event {
    background-color: rgba(15, 87, 150, 0.15) !important;
    border-left: 4px solid #0F5796 !important;
}

.toastui-calendar-weekday-event-dot {
    display: none !important;
}

.toastui-calendar-month .toastui-calendar-month-daygrid {
    background-color: white !important;
}

.year-list-container {
    width: 140vh;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: white;
    margin-bottom: 40px;
    padding: 5px 76px;
}

.year-list {
    display: flex;
    overflow-x: hidden;
}

.year-list .year-list-item {
    flex: 0 0 100px;
    height: 50px;
    font-size: 13px;
    padding: 0px 40px;
    color: black;
    text-align: center;
    line-height: 50px;
    margin-right: 10px;
}

.year-list .year-list-item-selected {
    background-color: #0F5796;
    color: white;
    border-radius: 10px;
}