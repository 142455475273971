
.template1 .report-container {
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-column-gap: 10px;
  margin-left: 0rem;
  margin-top: 0rem;
}


.template1 .report-container .add-left-side span {
  font-size: 14px;
  color: #a3a3a3;
  cursor: pointer;
}

.template1 .report-container .add-left-side h2 {
  font-size: 26px;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.template1 .right-side {
  margin-right: 2rem;
}