/* Dashboard Page Styles */
 

  .new-press-release-create-content {
    flex: 1;
    display: flex;
  }


  .create-left-new-press-release {
    flex: 1;
  }

  .create-right-column {
    flex: 1;
    padding: 0 20px;
    max-width: 30%;
   
  }

/* Card.css */

.new-press-release-card {
  display: flex;
  flex-direction: column;
  
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  height: 120px;
  background-color: #fff;
}

.new-press-release-image-container {
  background-color:#C4C4C4;
  width: 54px;
  height: 44px;
  border-radius: 30%;
  margin-right: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.card-image {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.new-press-release-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-container {
  flex-grow: 1;
}

.new-press-release-card-title {
  font-size: 18px;
  color: black;
  margin: 0;
}

.card-description {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0;
}

.expand-icon {
  font-size: 1.5rem;
  color: #999;
}

.new-press-release-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.industry-button,
.template-button {
  padding: 8px 12px;
  margin-right: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.industry-button {
  display: flex;
  align-items: center;
  background-color: #ffeaca;
  color: #FFBB54;
}

.template-button {
  background-color: #d1e7fa;
  color: #0F5796;
}

/* QuillEditor.css */

.quill-editor {
  width: 100%;
  padding: 0px;
  margin-top: 20px;
  border-radius: 30px;
  height: 40vh;
}

.quill-editor .ql-container {
  height: 100vh;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.quill-editor-notes > div {
  background: #FFF;
}

.quill-editor-notes .ql-container {
  height: 14vh;
}

.quill-editor .ql-toolbar {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* SearchDropdown.css */

.search-dropdown {
  width: 500px;
  margin-right: 20px;
  text-align: center;
}

.input-container {
  position: relative;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.plus-button {
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #fff;
  color: #0F5796;
  border: 1px solid #0F5796;
  border-radius: 20;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  width: 100px;
  z-index: 1;
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #f0f0f0;
}

.agents-title {
  div {
    color: #A3A3A3;
    width: 320px;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  font-size: 24px !important;
  font-weight: bold !important;
}

.agent-grid {
  align-items: center;
  padding: 8px 4px;
}

.agent-photo {
  margin-left: 10px;
  height: 64px;
  width: 64px;
  border-radius: 12px;
}

.agent-details {
  div:first-child {
    color: initial;
    font-size: 18px;
    font-weight: bold;
    margin-top: -4px;
  }
  div {
    color: #A3A3A3;
    font-size: 15px;
    margin-top: 18px;
  }
  padding-left: 36px;
}

.toggle-button {
  button {
    background: transparent;
    border-radius: 12px !important;
    box-shadow: "none";
    border: hidden;
    color: #0F5796;
    font-size: 16px;
    font-weight: 700;
    width: 50%;
  }
  button.active {
    background: #fff !important;
    color: #4F7086;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
  }
  width: 74%;
  height: 48px;
}